import * as React from 'react'
import { LoadingOutlined } from '@ant-design/icons'
// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Loading...</title>
      <div style={{ textAlign: 'center', padding: 40 }}>
        <p>Loading...</p>
        <LoadingOutlined />
      </div>
    </main>
  )
}

export default NotFoundPage
